
import { iconLines, iconService } from '@/components/icon'
import { useStore } from '@/store'
import { AppActions, AppMutation } from '@/store/types'
import { EventBuss, languageOptions } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import {
    computed,
    defineComponent,
    Emitter,
    inject,
    onBeforeMount,
    onMounted,
    ref
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export default defineComponent({
    components: {
        'icon-lines': iconLines,
        'icon-service': iconService
    },
    setup() {
        const emitter = inject('emitter') as Emitter
        const { t, locale } = useI18n()

        const router = useRouter()

        const store = useStore()

        const line1 = ref<boolean>()
        const line2 = ref<boolean>()
        const line3 = ref<boolean>()

        const serverStr = ref<string>('')

        const showLanguageOptions = ref(false)
        const langArr = ref(languageOptions)

        onBeforeMount(() => {
            window.sessionStorage.clear()
        })

        onMounted(() => {
            pingServer(1)
            pingServer(2)
            pingServer(3)
        })

        const clickLine = (line: number) => {
            if (line === 1 && !line1.value) return
            else if (line === 2 && !line2.value) return
            else if (line === 3 && !line3.value) return

            store.commit(AppMutation.APP_SET_LINE, line)
            router.push({ name: 'login' })
        }

        const isValidSevice = (urlStr: string): boolean => {
            const pattern = new RegExp(
                '^((ft|htt)ps?:\\/\\/)?' + // protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
                    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                    '(\\:\\d+)?' + // port
                    '(\\/[-a-z\\d%@_.~+&:]*)*' + // path
                    '(\\?[;&a-z\\d%@_.,~+&:=-]*)?' + // query string
                    '(\\#[-a-z\\d_]*)?$',
                'i'
            )
            return pattern.test(urlStr)
        }

        const enterLogin = () => {
            if (serverStr.value.length > 0) {
                let url = serverStr.value.replace(/\/$/, '')

                let appendHttp = true

                if (url.startsWith('https://')) {
                    appendHttp = false
                } else if (url.startsWith('http://')) {
                    appendHttp = false
                }

                if (appendHttp) url = `http://${url}`

                if (!isValidSevice(url)) {
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        t('serverselection.lineerror')
                    )
                    return
                }

                store.commit(AppMutation.SHOW_LOADING)

                store
                    .dispatch(AppActions.PING_SERVER, { line: -1, url })
                    .then((response) => {
                        store.commit(AppMutation.HIDE_LOADING)
                        console.log(response)
                        if (response === 'test ok') {
                            store.commit(AppMutation.APP_SET_LINE, -1)
                            store.commit(AppMutation.APP_SET_LINE_STR, url)
                            router.push({ name: 'login' })
                        }
                    })
                    .catch(() => {
                        store.commit(AppMutation.HIDE_LOADING)
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            t('serverselection.lineerror')
                        )
                    })
            }
        }

        const pingServer = async (line: number) => {
            const params = { line, url: '' }
            store
                .dispatch(AppActions.PING_SERVER, params)
                .then((response) => {
                    if (response === 'test ok') {
                        if (line === 1) line1.value = true
                        else if (line === 2) line2.value = true
                        else if (line === 3) line3.value = true
                    }
                })
                .catch(() => {
                    if (line === 1) line1.value = false
                    else if (line === 2) line2.value = false
                    else if (line === 3) line3.value = false
                })
        }
        const contactService = () => {
            window.open(process.env.VIP_SERVICE, '_blank')
        }

        const selectLanguage = () => {
            // store.commit(AppMutation.SET_LANG, str)
            // locale.value = str
            // showLanguageOptions.value = false
            const ev: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: 'lang'
            }
            emitter.emit(EventBuss.DIALOG, ev)
        }

        const language = computed((): string => {
            const result = langArr.value.find((x) => x.code === lang.value)
            return result?.name || ''
        })

        const lang = computed((): string => store.getters['lang'])

        return {
            line1,
            line2,
            line3,
            serverStr,
            lang,
            showLanguageOptions,
            language,
            langArr,
            selectLanguage,
            clickLine,
            enterLogin,
            contactService,
            t
        }
    }
})
